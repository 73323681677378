import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";

function PasswordReset() {

    window.onload = function() {
        if (userFlow === 2) {
            setErrorDisplay('none');
            setErrorDisplayMessage('none');
            setPasswordMatchErrorDisplayMessage("Password complexity not yet met");
            setPasswordMatchErrorDisplay("block");
            setResetButtonDisplayValue('none');
        }
    };

    let userFlow = 0;
    let tokenValue = "";

    const useStylesTextField = makeStyles(theme => ({
        root: {
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderColor: "white"
            },
            "&:before": {
                borderColor: "white"
            },
            color: "white",
            borderBottomColor: "white",
            "& label.Mui-focused": {
                color: "white"
            },
            "& label": {
                color: "white"
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: "white",
                color: "white"
            },
            "& .MuiInput-underline:before": {
                borderBottomColor: "white",
                color: "white"
            },
            "& .MuiInput-underline:hover": {
                borderBottomColor: "white",
                color: "white"
            },
            "& .MuiInput-underline": {
                borderBottomColor: "white",
                color: "white"
            },
            '&:hover:not($disabled):after': {
                borderBottomColor: "white",
                color: "white"
            },
            '&:hover:not($disabled):before': {
                borderBottomColor: "white",
                color: "white"
            },
            '&:hover': {
                borderBottomColor: "white",
                color: "white"
            },
        }
    }));

    const [UserValue, setUser] = useState('')
    const [PasswordValue, setPassword] = useState('')
    const [errorDisplayValue, setErrorDisplay] = useState('none')
    const [errorDisplayMessageValue, setErrorDisplayMessage] = useState('')
    const [passwordMatchDisplayValue, setPasswordMatchErrorDisplay] = useState('none')
    const [passwordMatchDisplayMessageValue, setPasswordMatchErrorDisplayMessage] = useState('')
    const [resetButtonDisplayValue, setResetButtonDisplayValue] = useState('')

    function resetPassword(uf) {
        if (uf === 1) {
            let emailAddress = UserValue;
            if (emailFormatIsValid(emailAddress)) {
                setErrorDisplay('none');
                const json = JSON.stringify({email: emailAddress});
                axios.post('/v1/sendpwdresettoken', json, {
                    headers: {'Content-Type': 'application/json'}
                }).then(response => { // Remove the type annotation from the response parameter
                    window.location = "/passwordreset?message=" + response.data.messsage;
                });
            } else {
                setErrorDisplay('block');
                setErrorDisplayMessage('Invalid Email Format');
            }
        }
        if (uf === 2) {
            if (tokenValue && PasswordValue) {
                setErrorDisplay('none');
                const json = JSON.stringify({resettoken: tokenValue, newpassword: PasswordValue});
                axios.post('/v1/passwordreset', json, {
                    headers: {'Content-Type': 'application/json'}
                }).then(response => {
                    window.location = "/passwordreset?finish=true";
                }).catch(reason => {
                    console.log(reason)
                    setErrorDisplay('block');
                    setErrorDisplayMessage('Password could not be reset' + reason);
                });
            } else {
                setErrorDisplay('block');
                setErrorDisplayMessage('Password could not be reset');
            }
        }
    }

    function emailFormatIsValid(email) {
        const re = /^[a-zA-Z0-9._'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(String(email).toLowerCase());
    }

    function returnToLogin() {
        window.location = '/login'
    }

    let Characters = document.getElementById('Characters');
    let Capital = document.getElementById('Capital');
    let Lowercase = document.getElementById('Lowercase');
    let Number = document.getElementById('Number');
    let Special = document.getElementById('Special');
    //let passwordLength = new RegExp (/(?=.{8,42})/);
    let password1LengthValid = 0;
    let passwordCapital = new RegExp(/(?=.*[A-Z])/);
    let password1CapitalValid = 0;
    let passwordLower = new RegExp(/(?=.*[a-z])/);
    let password1LowerValid = 0;
    let passwordNumber = new RegExp (/(?=.*[0-9])/);
    let password1NumberValid = 0;
    let passwordSpecial = new RegExp (/(?=.*[^A-Za-z0-9])/);
    let password1SpecialValid = 0;
    let passwordConfirmValid = 0;
    let validPassword = 0;

    function PasswordChecker(PasswordParameter) {
        if (userFlow === 2) {
            let passwordCompare1 = document.getElementById("password").value;
            let passwordCompare2 = document.getElementById("confirmPassword").value;

            if(passwordCompare1.length >= 12) {
                Characters.style.color = "#4dd34d"
                password1LengthValid = 1;
            } else {
                Characters.style.color = "Red"
                password1LengthValid = 0;
            }

            if (passwordCapital.test(passwordCompare1)) {
                Capital.style.color = "#4dd34d"
                password1CapitalValid = 1;
            } else {
                Capital.style.color = "Red"
                password1CapitalValid = 0;
            }

            if (passwordLower.test(passwordCompare1)) {
                Lowercase.style.color = "#4dd34d"
                password1LowerValid = 1;
            } else {
                Lowercase.style.color = "Red"
                password1LowerValid = 0;
            }

            if (passwordNumber.test(passwordCompare1)) {
                Number.style.color = "#4dd34d"
                password1NumberValid = 1;
            } else {
                Number.style.color = "Red"
                password1NumberValid = 0;
            }

            if (passwordSpecial.test(passwordCompare1)) {
                Special.style.color = "#4dd34d"
                password1SpecialValid = 1;
            } else {
                Special.style.color = "Red"
                password1SpecialValid = 0;
            }

            if (passwordCompare1 === passwordCompare2) {
                passwordConfirmValid = 1;
            } else {
                passwordConfirmValid = 0;
            }

            validPassword = password1LengthValid + password1CapitalValid + password1LowerValid + password1NumberValid + password1SpecialValid

            if (validPassword === 5 && passwordConfirmValid === 0) {
                setPasswordMatchErrorDisplayMessage("Confirm Password");
                setPasswordMatchErrorDisplay("block");
                setResetButtonDisplayValue('none');
            }
            if (validPassword < 5){
                setPasswordMatchErrorDisplayMessage("Password complexity not yet met");
                setPasswordMatchErrorDisplay("block");
                setResetButtonDisplayValue('none');
            }
            if (validPassword === 5 && passwordConfirmValid === 1) {
                setPasswordMatchErrorDisplay("none");
                setResetButtonDisplayValue('block');
                setPassword(PasswordParameter)
            }
        }
    }

    let showPasswordFields = 'none';
    let showUserFields = 'none';
    let showMessage = 'none';
    let displayMessage = '';
    let showLoginLink = 'none';

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let hasToken = urlParams.has('resettoken');
    let hasMessage = urlParams.has('message');
    let hasFinish = urlParams.has('finish');

    if (hasToken) {
        showPasswordFields = 'block';
        showUserFields = 'none';
        showMessage = 'none'
        userFlow = 2;
        tokenValue = urlParams.get('resettoken');
    } else {
        showPasswordFields = 'none';
        showUserFields = 'block';
        showMessage = 'none';
        userFlow = 1;
    }

    if (hasMessage) {
        showPasswordFields = 'none';
        showUserFields = 'none';
        showMessage = 'block';
        displayMessage = 'An email has been sent, please follow the instructions in the email to reset your password.';
    }

    useEffect(() => {
        function setResetButton(hasMessage) {
            if (hasMessage) {
                setResetButtonDisplayValue('none');
            }
        }
        setResetButton(hasMessage)
    }, [hasMessage]);

    if (hasFinish) {
        showPasswordFields = 'none';
        showUserFields = 'none';
        showMessage = 'block';
        displayMessage = 'Your password has been reset,';
        showLoginLink = 'block';
    }

    useEffect(() => {
        function setResetButton(hasFinish) {
            if (hasFinish) {                
                setResetButtonDisplayValue('none');
            }
        }

        setResetButton(hasFinish);
    }, [hasFinish]);

    return (
        <header className="base">
            <div className="topHeader">
            </div>
            <div className="topGap80 topGap80TextPosition">
                <div className="logoBackColor"><img alt='' className="logoBack"  src={"/images15512/InvestmentMetrics-logo-rgb_5918x488_1152dpi.png"} /></div>
                <div className="logoDisplay"><img alt='' className="logoBack1"  src={"/images15512/InvestmentMetrics-logo-small.png"} /></div>
                <div className="topGap80TextStyle"><span className=""> Insights for Success</span>
                    {/*{profileDataVal.FirstName}*/}
                </div>
            </div>
            <div className="centerBody">
                <div className="loginBox">
                    <div style={{display: errorDisplayValue}} className="error"><i className="fa fa-exclamation-triangle" aria-hidden="true"/> {errorDisplayMessageValue}</div>
                    <div className="loginForm">
                        <form id="loginForm" method="POST" action="/v1/login">

                            <input type="hidden" name="AuthMethod" value="FormsAuthentication"/>
                            <div className="textField">

                                <div className="loginHeader">InvestmentMetrics Password Reset</div><br />
                                <div style={{display: showPasswordFields}} id="Characters">12 or more characters</div>
                                <div style={{display: showPasswordFields}} id="Capital">1 or more capital letter(s)</div>
                                <div style={{display: showPasswordFields}} id="Lowercase">1 or more lowercase letter(s)</div>
                                <div style={{display: showPasswordFields}} id="Number">1 or more number(s)</div>
                                <div style={{display: showPasswordFields}} id="Special">1 or more special character(s)</div>

                                <TextField
                                    classes={useStylesTextField()}
                                    style={{display: showUserFields}}
                                    id="userid"
                                    name="UserName"
                                    label="Email"
                                    placeholder="Email Address"
                                    autoComplete="username"
                                    onChange={event => setUser(event.target.value)}
                                    fullWidth={true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fa fa-user whiteText" aria-hidden="true"/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="textField">
                                <TextField
                                    classes={useStylesTextField()}
                                    style={{display: showPasswordFields}}
                                    id="password"
                                    name="Password"
                                    label="Password"
                                    type="password"
                                    placeholder="Password"
                                    autoComplete="new-password"
                                    onChange={event => {PasswordChecker(event.target.value)}}
                                    fullWidth={true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fa fa-key" aria-hidden="true"/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="textField">
                                <TextField
                                    classes={useStylesTextField()}
                                    style={{display: showPasswordFields}}
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    placeholder="Password"
                                    autoComplete="new-password"
                                    onChange={event => PasswordChecker(event.target.value)}
                                    fullWidth={true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <i className="fa fa-key" aria-hidden="true"/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div style={{display: showMessage}}><span>{displayMessage} </span>
                                <span onClick={returnToLogin} className="passwordResetButton2"
                                      style={{display: showLoginLink}}>
                                return to login.
                            </span>
                            </div>

                        </form>
                    </div>
                    <div className="loginBoxFooter">
                        <div style={{display: passwordMatchDisplayValue}} className="error"><i className="fa fa-exclamation-triangle" aria-hidden="true"/> {passwordMatchDisplayMessageValue}</div>
                        <div style={{display: resetButtonDisplayValue}} onClick={() => resetPassword(userFlow)}
                             className="actionButton">
                            {'Reset'}
                        </div>
                        <div className="spaceBetween"/>
                        <div  onClick={returnToLogin}
                             className="actionButton">
                            Return to Login Page
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default PasswordReset;
export {default as PasswordReset} from "./passwordReset";